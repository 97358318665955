import fecha from "fecha";
import iconActionAllow from "./images/icon-action-allow.svg";
import iconActionBlock from "./images/icon-action-block.svg";
import iconActionDetect from "./images/icon-action-detect.svg";
import iconTypeNeutral from "./images/icon-type-program.svg";
import iconTypeMemory from "./images/icon-type-memory.svg";
import iconTypeMalware from "./images/icon-malware.svg";

// Action helpers
//
export const action2text = (action) => ({
    "FileAllowed": "Allowed",
    "UnknownFileBlocked": "Blocked",
    "UnknownFileDetected": "Detected",
    "ProcessHalted": "Halted",
    "AccessAllowed": "Allowed",
    "AccessBlocked": "Blocked",
    "Income": "Incoming",
    "Outcome": "Outgoing",
    "Blocked": "Blocked",
    "Allowed": "Allowed",
    "Detected": "Detected",
})[action] || action;

export const action2icon = (action) => ({
    "FileAllowed": iconActionAllow,
    "UnknownFileBlocked": iconActionBlock,
    "UnknownFileDetected": iconActionDetect,
    "ProcessHalted": iconActionBlock,
    "AccessAllowed": iconActionAllow,
    "AccessBlocked": iconActionBlock,
    "None": iconActionAllow,
    "Success": iconActionAllow,
    "Fail": iconActionDetect,
    "Income": iconActionDetect,
    "Outcome": iconActionDetect,
    "Blocked": iconActionBlock,
    "Allowed": iconActionAllow,
    "Detected": iconActionDetect,
})[action];

// Type helpers
//
export const type2text = (type) => ({
    "Executable": "Unknown Program",
    "Script": "Unknown Script",
    "SharedObject": "Unknown Shared Object",
    "MemoryProtection": "Code Injection",
    "MandatoryAccessControl": "Mandatory Access Control",
    "KernelModule": "Unknown Kernel Module",
    "Heartbeat": "Heartbeat",
    "PolicyLoad": "Policy Load",
    "AppCrash": "Application Crash",
    "NetworkConnection": "Network Connection",
    "NetworkUsage": "Network Usage",
    "Command": "Command",
    "Startup": "Startup",
    "Login": "Login",
    "SystemTimeUpdate": "System Time Update",
    "XGuardAgentError": "XGuard Agent Error",
    "CPUUsage": "CPU Usage",
    "MemoryUsage": "Memory Usage",
    "Custom": "Custom",
    "FileOperation": "File Operation",
    "Mount": "Mount",
    "Container": "Unknown Container",
})[type];

export const type2icon = (type) => ({
    "Executable": iconTypeMalware,
    "Script": iconTypeMalware,
    "SharedObject": iconTypeMalware,
    "MemoryProtection": iconTypeMemory,
    "MandatoryAccessControl": iconTypeMalware,
    "KernelModule": iconTypeMalware,
    "Heartbeat": iconTypeNeutral,
    "PolicyLoad": iconTypeNeutral,
    "AppCrash": iconTypeMemory,
    "NetworkConnection": iconTypeNeutral,
    "NetworkUsage": iconTypeNeutral,
    "Command": iconTypeMemory,
    "Startup": iconTypeNeutral,
    "Login": iconTypeNeutral,
    "SystemTimeUpdate": iconTypeNeutral,
    "XGuardAgentError": iconTypeNeutral,
    "CPUUsage": iconTypeNeutral,
    "MemoryUsage": iconTypeNeutral,
    "Custom": iconTypeNeutral,
    "FileOperation": iconTypeNeutral,
    "Mount": iconTypeNeutral,
    "Container": iconTypeMalware,
})[type];

// Dates helpers
//
export const formatTimeLong = (timeStr) => fecha.format(new Date(timeStr), "MMM DD YYYY, HH:mm:ss");

export const formatTimeShort = (timeStr) => fecha.format(new Date(timeStr), "MMM DD, HH:mm");

export const formatTimeForChart = (timeStr) => fecha.format(new Date(timeStr), "MM-DD");

export const dateOffset = (date, offset) => {
    date.setDate(date.getDate() + offset);
    return date;
};
