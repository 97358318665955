import {
    FILTER_DATES_ALL,
    FILTER_DATES_TODAY,
    FILTER_DATES_YESTERDAY,
    FILTER_DATES_LAST_7_DAYS,
    FILTER_DATES_LAST_30_DAYS,
} from '../../../../common/constants';
import {
    FILTER_TYPES_ALL,
    FILTER_TYPES_ATTACKS,
    FILTER_TYPES_EXECUTABLE,
    FILTER_TYPES_SCRIPT,
    FILTER_TYPES_SHAREDOBJECT,
    FILTER_TYPES_MEMORYPROTECTION,
    FILTER_TYPES_MANDATORYACCESSCONTROL,
    FILTER_TYPES_KERNELMODULE,
    FILTER_TYPES_POLICYLOAD,
    FILTER_TYPES_HEARTBEAT,
    FILTER_TYPES_APPCRASH,
    FILTER_TYPES_NETWORKCONNECTION,
    FILTER_TYPES_NETWORKUSAGE,
    FILTER_TYPES_COMMAND,
    FILTER_TYPES_STARTUP,
    FILTER_TYPES_LOGIN,
    FILTER_TYPES_SYSTEMTIMEUPDATE,
    FILTER_TYPES_XGUARDAGENTERROR,
    FILTER_TYPES_CPUUSAGE,
    FILTER_TYPES_MEMORYUSAGE,
    FILTER_TYPES_CUSTOM,
    FILTER_TYPES_FILEOPERATION,
    FILTER_TYPES_MOUNT,
    FILTER_TYPES_CONTAINER,
} from '../../../../common/constants';

export const dateFilterOptions = [
    { value: FILTER_DATES_ALL, label: 'All times' },
    { value: FILTER_DATES_TODAY, label: 'Today' },
    { value: FILTER_DATES_YESTERDAY, label: 'Yesterday' },
    { value: FILTER_DATES_LAST_7_DAYS, label: 'Last 7 days' },
    { value: FILTER_DATES_LAST_30_DAYS, label: 'Last 30 days' },
];

export const typeFilterOptions = [
    { value: FILTER_TYPES_ALL, label: 'All types' },
    { value: FILTER_TYPES_ATTACKS, label: 'All attacks' },
    { value: FILTER_TYPES_EXECUTABLE, label: 'Unknown Program' },
    { value: FILTER_TYPES_SCRIPT, label: 'Unknown Script' },
    { value: FILTER_TYPES_SHAREDOBJECT, label: 'Unknown Shared Object' },
    { value: FILTER_TYPES_KERNELMODULE, label: 'Unknown Kernel Module' },
    { value: FILTER_TYPES_MEMORYPROTECTION, label: 'Code Injection' },
    { value: FILTER_TYPES_MANDATORYACCESSCONTROL, label: 'Mandatory Access Control' },
    { value: FILTER_TYPES_POLICYLOAD, label: 'Policy Load' },
    { value: FILTER_TYPES_HEARTBEAT, label: 'Heartbeat' },
    { value: FILTER_TYPES_APPCRASH, label: 'Application Crash' },
    { value: FILTER_TYPES_NETWORKCONNECTION, label: 'Network Connection' },
    { value: FILTER_TYPES_NETWORKUSAGE, label: 'Network Usage' },
    { value: FILTER_TYPES_COMMAND, label: 'Command' },
    { value: FILTER_TYPES_STARTUP, label: 'Startup' },
    { value: FILTER_TYPES_LOGIN, label: 'Login' },
    { value: FILTER_TYPES_SYSTEMTIMEUPDATE, label: 'System Time Update' },
    { value: FILTER_TYPES_XGUARDAGENTERROR, label: 'XGuard Agent Error' },
    { value: FILTER_TYPES_CPUUSAGE, label: 'CPU Usage' },
    { value: FILTER_TYPES_MEMORYUSAGE, label: 'Memory Usage' },
    { value: FILTER_TYPES_CUSTOM, label: 'Custom' },
    { value: FILTER_TYPES_FILEOPERATION, label: 'File Operation' },
    { value: FILTER_TYPES_MOUNT, label: 'Mount' },
    { value: FILTER_TYPES_CONTAINER, label: 'Unknown Container' },
];
