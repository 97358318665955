// Common constants for Server and Client

// Action Center > Events
export const E_TYPE_PROJECT_NEW = 'ProjectNew';
export const E_TYPE_PROJECT_INSTALL = 'ProjectInstall';
export const E_TYPE_PROJECT_POLICY_WL = 'ProjectPolicyWhitelist';
export const E_TYPE_PROJECT_POLICY_IM = 'ProjectPolicyInMemory';
export const E_TYPE_PROJECT_COLLABORATOR_ADD = 'ProjectCollaboratorAdd';
export const E_TYPE_PROJECT_COLLABORATOR_ADD_OTHER = 'ProjectCollaboratorAddOther';
export const E_TYPE_PROJECT_COLLABORATOR_REMOVE = 'ProjectCollaboratorRemove';
export const E_TYPE_PROJECT_COLLABORATOR_REMOVE_OTHER = 'ProjectCollaboratorRemoveOther';
export const E_TYPE_PROJECT_COLLABORATOR_SET_OWNER = 'ProjectCollaboratorSetOwner';

export const E_SEVERITY_SUCCESS = 'Success';
export const E_SEVERITY_INFO = 'Info';
export const E_SEVERITY_WARNING = 'Warning';
export const E_SEVERITY_DANGER = 'Danger';

// In-Memory Policy > Exclusion type
export const EXCLUSION_TYPE_BASENAME = 'basename';
export const EXCLUSION_TYPE_GLOB = 'glob';

// Email addresses
export const EMAIL_ADDR_DEVOPS = 'devops@karambasecurity.com';
export const EMAIL_ADDR_TEAM = 'team@karambasecurity.com';

// Incidents filter > Dates
export const FILTER_DATES_ALL = 'all';
export const FILTER_DATES_TODAY = 'today';
export const FILTER_DATES_YESTERDAY = 'yesterday';
export const FILTER_DATES_LAST_7_DAYS = 'last-7d';
export const FILTER_DATES_LAST_30_DAYS = 'last-30d';

// Incidents filter > Types
export const FILTER_TYPES_ALL = 'all';
export const FILTER_TYPES_ATTACKS = 'attacks';
export const FILTER_TYPES_EXECUTABLE = 'executable';
export const FILTER_TYPES_SCRIPT = 'script';
export const FILTER_TYPES_SHAREDOBJECT = 'sharedobject';
export const FILTER_TYPES_KERNELMODULE = 'kernelmodule';
export const FILTER_TYPES_MEMORYPROTECTION = 'memoryprotection';
export const FILTER_TYPES_MANDATORYACCESSCONTROL = 'mandatoryaccesscontrol';
export const FILTER_TYPES_POLICYLOAD = 'policyload';
export const FILTER_TYPES_HEARTBEAT = 'heartbeat';
export const FILTER_TYPES_APPCRASH = 'appcrash';
export const FILTER_TYPES_NETWORKCONNECTION = 'networkconnection';
export const FILTER_TYPES_NETWORKUSAGE = 'networkusage';
export const FILTER_TYPES_COMMAND = 'command';
export const FILTER_TYPES_STARTUP = 'startup';
export const FILTER_TYPES_LOGIN = 'login';
export const FILTER_TYPES_SYSTEMTIMEUPDATE = 'systemtimeupdate';
export const FILTER_TYPES_XGUARDAGENTERROR = 'xguardagenterror';
export const FILTER_TYPES_CPUUSAGE = 'cpuusage';
export const FILTER_TYPES_MEMORYUSAGE = 'memoryusage';
export const FILTER_TYPES_CUSTOM = 'custom';
export const FILTER_TYPES_FILEOPERATION = 'fileoperation';
export const FILTER_TYPES_MOUNT = 'mount';
export const FILTER_TYPES_CONTAINER = 'container';
